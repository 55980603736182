.search-container {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    /*padding: 0;*/
    /*margin: 0;*/
    /*max-width: 100%;*/
    /*background-color: white*/
}

/*.search-input {*/
/*    padding-left: 5%;*/
/*    padding-right: 5%;*/
/*}*/
