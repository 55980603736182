.week {
    position: relative;
    margin: auto;
    justify-content: center;
    display: flex;
    min-width: 600px;
    width: 50%
}

.month-label {
    position: absolute;
    left: 100%;
    width: 200px;
    font-size: 1.5rem;
    padding: 5px
}
