.day {
    width: calc(100%/7);
    margin: 10px;
    padding: 5px;
    height: 150px;
    text-align: right;
}

.game-info {
    font-size: 12px;
    padding: 3px;
}